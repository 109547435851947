import React, { useEffect, useState } from "react";
import {
  fadeInOut,
  staggerItems,
  item,
  dropIn,
} from "../../../../utils/animations";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";

import Confetti from "react-confetti";
import useWindowDimensions from "utils/useWindowDimensions";
import { importImages } from "utils/importImages";
import { useTranslation } from "react-i18next";
import { CountDownPreGame } from "components/CountDown/CountDownPreGame";

import {
  showSuccessReducer,
  completedGameReducer,
  showFailReducer,
  currentStepReducer,
} from "../../../../store/gameSlice";

import Card from "./Card";
import CountDown from "../../../../components/CountDown/CountDown";
import Wrapper from "../../../../components/Wrapper/Wrapper";

import Button from "../../../../components/Button/Button";

import soundStart from "../../../../assets/sounds/start.wav";
import soundTickLose from "../../../../assets/sounds/Wrong_answer.wav";
import soundTickWin from "../../../../assets/sounds/Correct_answer.mp3";
import soundWin from "../../../../assets/sounds/Correct.wav";
import soundLost from "../../../../assets/sounds/Error.wav";

const images = importImages(
  require.context("assets/images/_jub", false, /\.(png|jpe?g|svg)$/)
);

function Game({ gameData }) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const dataMemoryGame = [
    "memory1_a",
    "memory1_b",
    "memory2_a",
    "memory2_b",
    "memory3_a",
    "memory3_b",
    "memory4_a",
    "memory4_b",
    "memory5_a",
    "memory5_b",
    "memory6_a",
    "memory6_b",
    "memory7_a",
    "memory7_b",
    currentLanguage === "hr" ? "memoryhr8_a" : "memory8_a",
    currentLanguage === "hr" ? "memoryhr8_b" : "memory8_b",
  ];

  const [data, setData] = useState([]);
  const [deck, setDeck] = useState(genDeck());
  const [errors, setErrors] = useState(0);
  const [matches, setMatch] = useState([]);
  const [flipping, setFlip] = useState({ i: [], v: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [showScore, setShowScore] = useState(null);
  const [showBoost, setShowBoost] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [completeCountdown, setCompleteCoundown] = useState(false);

  const dispatch = useDispatch();

  const currentStep = useSelector((state) => state.game.currentStep);
  const showSuccess = useSelector((state) => state.game.showSuccess);
  const showFail = useSelector((state) => state.game.showFail);
  const isPlaying = useSelector((state) => state.game.isPlaying);

  const { height, width } = useWindowDimensions();

  const navigate = useNavigate();

  const [playStart] = useSound(soundStart, {
    volume: 0.5,
    soundEnabled: isPlaying,
  });

  const [playTickLose] = useSound(soundTickLose, {
    volume: 0.5,
    soundEnabled: isPlaying,
  });

  const [playTickWin] = useSound(soundTickWin, {
    volume: 0.8,
    soundEnabled: isPlaying,
  });

  const [playWin] = useSound(soundWin, {
    volume: 0.5,
    soundEnabled: isPlaying,
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (flipping.i.length === 2) {
      checkFlip(flipping);
    }

    // Next level if finished

    if (finished) {
      setShowConfetti(true);
      setTimeout(() => {
        if (deck.length === dataMemoryGame.length) {
          dispatch(completedGameReducer("memorygame"));
          setShowScore("success");
          playWin();
          window.plausible("quizmemory_complete");
        }
      }, 500);
    }
  }, [flipping]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(showFailReducer(false));

    var arr = dataMemoryGame.map(function (i) {
      return i;
    });

    // there might be better ways of doing this
    let shuffled = arr.sort(() => Math.random() - 0.5);

    setData(shuffled);

    setIsLoading(false);
    window.plausible("p_start_zacaran_spomin");
    // Set countdown
  }, []);

  useEffect(() => {
    if (errors > 0) {
      setShowBoost(true);
      //playTick();
    }

    setTimeout(() => {
      setShowBoost(false);
    }, 500);
  }, [errors]);

  function genDeck() {
    let shuffled = dataMemoryGame.sort(() => Math.random() - 0.5);

    return shuffled;
  }

  function checkFlip(f) {
    if (f.v[0] === f.v[1]) {
      setMatch([...matches, f.v[0]]);
      setFlip({ i: [], v: [] });
      playTickWin();
    } else {
      setErrors(() => errors + 1);
      // playTickLose();
      setTimeout(() => {}, 150);

      setTimeout(() => {
        setFlip({ i: [], v: [] });
      }, 500);
    }
  }

  function reshuffle() {
    setFlip({ i: [], v: [] });
    setMatch([]);
    setErrors(0);

    setTimeout(() => setDeck(data), 500);
  }

  function cardClick(value, index) {
    const { i, v } = flipping;
    if (i.length < 2) {
      setFlip({ i: [...i, index], v: [...v, value] });
    }
  }
  const cards = data.map((value, i) => (
    <Card
      key={i}
      onClick={() => cardClick(value.substring(0, value.length - 2), i)}
      value={value}
      matched={matches.includes(value.substring(0, value.length - 2))}
      flipping={flipping.i.includes(i)}
    />
  ));

  const finished = matches.length === deck.length / 2;

  const success = () => {
    dispatch(completedGameReducer("memorygame"));
    // dispatch(showSuccessReducer(true));
  };

  const resetGame = () => {
    setShowScore(null);
    reshuffle();
    dispatch(showFailReducer(false));
    setCompleteCoundown(false);
  };

  const completeGame = () => {
    dispatch(completedGameReducer("memorygame"));
    navigate("/games");
  };

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className={`messages ingame d-flex justify-content-center align-items-center ${gameData.id}`}>
      {showConfetti && (
        <Confetti
          width={width}
          height={height}
          colors={["#bfa266", "#642076"]}
          numberOfPieces={200}
          friction={0.99}
          gravity={0.3}
          tweenDuration={500}
          recycle={false}
          opacity={0.8}
          drawShape={(ctx) => {
            function strokeStar(x, y, r, n, inset) {
              ctx.save();
              ctx.beginPath();
              ctx.translate(x, y);
              ctx.moveTo(0, 0 - r);
              for (var i = 0; i < n; i++) {
                ctx.rotate(Math.PI / n);
                ctx.lineTo(0, 0 - r * inset);
                ctx.rotate(Math.PI / n);
                ctx.lineTo(0, 0 - r);
              }
              ctx.closePath();
              ctx.fill();
              ctx.restore();
            }
            strokeStar(5, 6, 3, 4, 4);
          }}
        />
      )}

      <div className="container narrow vh-100 text-center d-flex align-items-center justify-content-center">
        <div className="w-100">
          <h1
            className="text-white"
            dangerouslySetInnerHTML={{ __html: t("G2H") }}
          />
          <div className="question-section">
            <p
              className="question-text text-center text-white"
              dangerouslySetInnerHTML={{ __html: t("G2P") }}
            />
          </div>

          <div className="mt-3 game-container position-relative">
            {!completeCountdown && (
              <div className="overlay-content d-flex align-items-center justify-content-center px-3 px-md-5">
                <CountDownPreGame
                  completeCountdown={completeCountdown}
                  setCompleteCoundown={setCompleteCoundown}
                />
                <div className="overlay" />
              </div>
            )}

            {showScore === "success" && (
              <motion.div
                initial={{ opacity: 0, translateX: 0 }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: 100 }}
                className="score">
                <div className="overlay-content d-flex align-items-center justify-content-center px-3 px-md-5">
                  <div>
                    <h1
                      dangerouslySetInnerHTML={{
                        __html: t(gameData.successh),
                      }}
                    />
                    <p
                      className="text-white lead"
                      dangerouslySetInnerHTML={{
                        __html: t(gameData.successp),
                      }}
                    />

                    <Button
                      className="btn btn-orange px-5 mt-3"
                      label={t("Next")}
                      onClick={() => navigate("/games")}
                    />
                  </div>
                </div>
                <div className="overlay" />
              </motion.div>
            )}
            {showFail && (
              <motion.div
                initial={{ opacity: 0, translateX: 0 }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: 100 }}
                className="score">
                <div className="overlay-content d-flex align-items-center justify-content-center px-3 px-md-5">
                  <div>
                    <h1
                      dangerouslySetInnerHTML={{
                        __html: t(gameData.failh),
                      }}
                    />
                    <p
                      className="text-white lead"
                      dangerouslySetInnerHTML={{
                        __html: t(gameData.failp),
                      }}
                    />

                    <Button
                      className="btn btn-orange px-5 mt-3"
                      label={t("Playagain")}
                      onClick={() => resetGame()}
                    />
                    <div>
                      <Button
                        className="btn btn-sm px-5 mt-3 text-decoration-underline"
                        onClick={() => completeGame()}
                        label={t("EndChallenge")}
                      />
                    </div>
                  </div>
                </div>
                <div className="overlay" />
              </motion.div>
            )}

            <div className="p-2 p-md-3">
              <CountDown
                errors={errors}
                gameData={gameData}
                showScore={showScore}
                completeCountdown={completeCountdown}
              />

              {showBoost ? (
                <div className="score-booster d-none">
                  <span>-1s</span>
                </div>
              ) : null}
              <motion.div
                variants={dropIn}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="deck">
                {cards}
              </motion.div>
            </div>
          </div>
          <div className="dev-buttons d-none">
            <Button
              label="Zaključi kot success"
              onClick={success}
              className="m-1 btn btn-sm btn-texture mt-4"
            />

            <Button
              label="Zaključi kot fail"
              onClick={() => dispatch(showFailReducer(true))}
              className="m-1 btn btn-sm btn-texture mt-4"
            />
          </div>
        </div>
      </div>
      <img
        className="position-absolute d-none d-xl-block"
        style={{ top: "60px", left: "15%" }}
        src={images["elm-marjetica.png"]}
      />
      <img
        className="position-absolute d-none d-xl-block"
        style={{ top: "20%", right: "10%" }}
        src={images["elm-zvezda.png"]}
      />
      <img
        className="position-absolute d-none d-xl-block"
        style={{ top: "60%", right: "5%" }}
        src={images["elm-marjetica.png"]}
      />

      <img
        className="position-absolute d-none d-xl-block"
        style={{ bottom: "10%", left: "5%" }}
        src={images["elm-skoljka1.png"]}
      />
      <img
        className="position-absolute d-none d-xl-block"
        style={{ bottom: "0%", right: "15%" }}
        src={images["elm-skoljka2.png"]}
      />
    </motion.div>
  );
}

export default Game;
