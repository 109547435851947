import { useTranslation } from "react-i18next";
import imgLogoJub from "assets/images/_jub/logo-jub.svg";
import imgLogoEb from "assets/images/_jub/logo-eb.svg";

const Footer = ({ currentLanguage }) => {
  const { t } = useTranslation();
  return (
    <div className="footer bg-white border-top border-blue w-100">
      <div className="row g-0 d-flex align-items-center text-blue py-2 px-3">
        <div className="col">
          <>
            <a href={`/${t("PrivacyLink")}?v2`} target="_blank">
              <u>{t("Privacy")}</u>
            </a>
          </>
        </div>
        <div className="col text-center">
          <a href="https://www.jub.si/" target="_blank">
            <img src={imgLogoJub} />
          </a>
        </div>
        <div className="col text-end">
          <a href="https://www.escapebox.si" target="_blank">
            <img src={imgLogoEb} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
