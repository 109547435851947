import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { resetGame } from "store/gameSlice";
import { motion } from "framer-motion";
import Confetti from "react-confetti";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { fadeInOut, dropIn, scaleIn } from "../../../utils/animations";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { RWebShare } from "react-web-share";

import Wrapper from "../../../components/Wrapper/Wrapper";

import OptIn from "../../OptIn/OptIn";
import Button from "../../../components/Button/Button";

import GamesShieldIcon from "../GamesShieldIcon";

import imgElementMemoryGame from "../../../assets/images/bg_element_zacaran_spomin.png";
import imgElementCarobniMagneti from "../../../assets/images/bg_element_carobnimagenti.png";
import imgElementSkrivnostneRazlike from "../../../assets/images/bg_element_skrivnostnerazlike.png";
import imgElementSuccess from "../../../assets/images/bg_success.jpg";
import imgKlobuk from "../../../assets/images/icon_klobuk.png";

import ShareFb from "../../../assets/images/share-fb.svg";

function Success({ gameData, currentLanguage }) {
  const [step, setStep] = useState("init");
  const [allCompleted, setAllCompleted] = useState(false);
  const [voteItems, setVoteItems] = useState([]);
  const dispatch = useDispatch();
  const completedGame = useSelector((state) => state.game.completedGame);
  const optIn = useSelector((state) => state.game.optIn);
  const { t } = useTranslation();

  const { height, width } = useWindowDimensions();

  const clickHandlerRestart = () => {
    dispatch(resetGame());
    window.location.href = "/games";
  };

  const Votes = () => {
    const sum = voteItems.slice(1).reduce((accumulator, item) => {
      return accumulator + parseInt(item.total);
    }, 0);

    const items = voteItems.map((item) => {
      if (item.avatar === null || item.avatar === "test") {
        return null;
      }

      return (
        <tr>
          <td>{item.avatar}</td>
          <td>
            {percentCalc(sum, parseInt(item.total))} %, {item.total}
          </td>
        </tr>
      );
    });

    return items;
  };

  useEffect(() => {
    // Check if all games are completed
    const allGamesCompleted = completedGame.every(
      (obj) => obj.completed === true
    );
    if (allGamesCompleted) {
      setAllCompleted(true);
    }

    axios
      .get(
        "https://api.escapebox.si/quizbox/quiz/81ed7d7c-1d3a-jubjupol/user-groups"
      )
      .then((res) => {
        setVoteItems(res.data);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });

    //console.log("allCompleted",completedGame);
  }, []);

  useEffect(() => {
    if (step === "final") {
      axios
        .post(
          "https://api.escapebox.si/quizbox/basic/81ed7d7c-1d3a-jubjupol/complete",
          {},
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((res) => {
          window.plausible("p_success_all");
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    }
  }, [step]);

  return (
    <>
      {optIn === false ? (
        <OptIn
          setStep={setStep}
          step="after"
          currentLanguage={currentLanguage}
        />
      ) : (
        <motion.div
          variants={dropIn}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="messages success-final d-flex justify-content-center align-items-center">
          <div className="container narrow mt-6 text-center">
            <h1
              className="mb-3"
              dangerouslySetInnerHTML={{ __html: t("LastH") }}
            />
            <div
              className="lead"
              dangerouslySetInnerHTML={{ __html: t("LastP") }}
            />

            <div className="lead my-5">
              <div>
                <table class="customTable">
                  <thead>
                    <tr>
                      <th>Vrtić</th>
                      <th>Postotak</th>
                    </tr>
                  </thead>
                  <tbody>
                    <Votes />
                  </tbody>
                </table>
              </div>
            </div>

            <div className="mt-3 d-flex align-items-center justify-content-center">
              <RWebShare
                closeText={t("Close")}
                data={{
                  text: t("ShareText"),
                  url: t("ShareURL"),
                  title: t("ShareTitle"),
                  sites: ["facebook, twitter, linkedin"],
                }}>
                <button className="btn btn-orange text-white fw-bold shadow-none px-5 d-flex align-items-center">
                  <svg
                    className="me-3"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink">
                    <rect
                      width="24"
                      height="24"
                      stroke="none"
                      fill="#FFFFFF"
                      opacity="0"
                    />

                    <g transform="matrix(0.53 0 0 0.53 12 12)">
                      <path
                        style={{ fill: "white" }}
                        transform=" translate(-24, -24)"
                        d="M 36.5 5 C 34.47615594919239 5.002544452598784 32.56897802117004 5.94763484931806 31.341113886518954 7.556454566709016 C 30.11324975186787 9.165274284099972 29.704823744468026 11.25422418861804 30.236328 13.207030999999999 L 30.121094 13.263672 L 16.738281 19.953125 L 16.623047 20.009766 C 15.39332896788356 18.428455155958808 13.503183536727201 17.50247834212681 11.500000000000002 17.5 C 7.910149126099842 17.5 5 20.410149126099842 5 24 C 5 27.589850873900158 7.910149126099842 30.5 11.5 30.5 C 13.504555642390812 30.49872542361561 15.396392221204714 29.572626213604625 16.626953 27.990234 L 16.738281 28.046875 L 30.121094 34.736328 L 30.230469 34.791016 C 29.697969605907552 36.74508353627564 30.106748433415046 38.83569607099942 31.33605041373394 40.4452774396227 C 32.56535239405284 42.05485880824599 34.47467651162492 42.99944459800791 36.5 43 C 40.089850873900154 43 43 40.089850873900154 43 36.5 C 43 32.91014912609984 40.089850873900154 30 36.5 30 C 34.657478304773214 30.002582713787792 32.90262134802197 30.787015086319865 31.671875 32.158203 L 31.460938 32.052734 L 18.080078 25.363281 L 17.871094 25.259766 C 18.03452022939938 24.428465458687356 18.033856787366997 23.57323398659428 17.869141 22.742188 L 18.080078 22.636719 L 31.460938 15.947266 L 31.666016 15.84375 C 32.8984784197762 17.21573132251185 34.65573966424363 17.999576399033188 36.5 18 C 40.089850873900154 18 43 15.089850873900158 43 11.5 C 43 7.910149126099842 40.089850873900154 5 36.5 5 z"
                        stroke-linecap="round"
                      />
                    </g>
                  </svg>
                  {t("Share")}
                </button>
              </RWebShare>
            </div>
            <Button
              label={t("FromStart")}
              onClick={() => clickHandlerRestart()}
              className="btn  text-decoration-underline mb-2 mt-1"
            />
            <div className="mt-5">
              <h1
                className="mb-3"
                dangerouslySetInnerHTML={{ __html: t("HCheckJupol") }}
              />
              <div
                className="lead mb-4"
                dangerouslySetInnerHTML={{ __html: t("CheckJupol") }}
              />

              <a
                className="btn btn-orange mb-2"
                href={t("SuccessLink")}
                target="_blank">
                {t("CheckJupol2")}
              </a>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
}
function percentCalc(sum, curr) {
  return Math.round((curr * 100) / sum);
}
export default Success;
