import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Image,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {
  fadeInOut,
  staggerItems,
  item,
  dropIn,
} from "../../../../utils/animations";
import Confetti from "react-confetti";
import ConfettiInt from "react-dom-confetti";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import useSound from "use-sound";
import CountDown from "components/CountDown/CountDown";
import { importImages } from "utils/importImages";
import { completedGameReducer } from "store/gameSlice";
import useWindowDimensions from "utils/useWindowDimensions";

import wrongSound from "assets/sounds/Error.wav";
import winSound from "assets/sounds/Correct.wav";
import soundTickLose from "assets/sounds/Wrong_answer.wav";
import soundTickWin from "assets/sounds/Correct_answer.mp3";

import { motion, AnimatePresence } from "framer-motion";
var dateVariable = new Date();
var todayDay = dateVariable.getDate();

const images = importImages(
  require.context("assets/images/_jub", false, /\.(png|jpe?g|svg)$/)
);

function QuizSteps({ gameData }) {
  // Shuffle questions
  const [randomizeSteps, setRandomizeSteps] = useState([]);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(true);
  const [score, setScore] = useState([]);
  const [correct, setCorrect] = useState(false);
  const [disable, setDisable] = useState(false);
  const [showScore, setShowScore] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const isPlaying = useSelector((state) => state.game.isPlaying);

  const [playWrong] = useSound(wrongSound);
  const [playWin] = useSound(winSound);

  const [playTickLose] = useSound(soundTickLose, {
    volume: 0.5,
    soundEnabled: isPlaying,
  });

  const [playTickWin] = useSound(soundTickWin, {
    volume: 0.8,
    soundEnabled: isPlaying,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();

  const { t } = useTranslation();

  const config = {
    angle: 90,
    spread: 45,
    startVelocity: 70,
    elementCount: 70,
    dragFriction: 0.1,
    duration: 2000,
    stagger: 1,
    width: "15px",
    height: "15px",
    perspective: "500px",
    colors: ["#f58634", "#6ab2dc"],
  };

  const handleAnswerOptionClick = (e, isCorrect) => {
    const id = e.target.id;
    var clickedAnswerButton = document.getElementById(id);
    var allAnswersButtons = document.querySelectorAll(".answer-section button");
    setDisable(true);
    setScore([...score, isCorrect]);

    if (isCorrect) {
      clickedAnswerButton.classList.add("correct");
      setCorrect(true);
      playTickWin();
    } else {
      clickedAnswerButton.classList.add("wrong");
      //setShow(true);
      playTickLose();
    }

    setTimeout(() => {
      setDisable(false);

      allAnswersButtons.forEach(function (element) {
        element.classList.remove("correct");
        element.classList.remove("wrong");
      });
    }, 2000);
  };

  const resetGame = () => {
    navigate("/games");
    setShowScore(null);
    setScore([]);
    setCurrentQuestion(0);
  };

  const completeGame = () => {
    setShowScore(null);
    dispatch(completedGameReducer("quizsteps"));
    navigate("/games");
  };

  useEffect(() => {
    const randomizeArray = [];
    // Loop over available steps
    for (var i = 0; i < gameData.step.length; i++) {
      const item = gameData.step[i];

      // Shuffle answers
      shuffle(item.answerOptions);

      randomizeArray.push(item);
    }

    setRandomizeSteps(randomizeArray);
    setIsLoadingQuestions(false);
  }, []);

  useEffect(() => {
    const nextQuestion = currentQuestion + 1;

    setTimeout(() => {
      if (nextQuestion < randomizeSteps.length) {
        setCurrentQuestion(nextQuestion);
        setCorrect(false);
      } else if (nextQuestion === randomizeSteps.length) {
        window.plausible("quizsteps_complete");

        if (allAreTrue(score)) {
          setShowScore("success");
          setShowConfetti(true);
          playWin();
          dispatch(completedGameReducer("quizsteps"));
        } else {
          setShowScore("fail");
          playWrong();
        }
      }
    }, 2000);
  }, [score]);

  return (
    <>
      {gameData.step ? (
        <motion.div
          variants={fadeInOut}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="quizsteps pt-6 pt-md-5">
          {showConfetti && (
            <Confetti
              width={width}
              height={height}
              colors={["#bfa266", "#642076"]}
              numberOfPieces={200}
              friction={0.99}
              gravity={0.3}
              tweenDuration={500}
              recycle={false}
              opacity={0.8}
              drawShape={(ctx) => {
                function strokeStar(x, y, r, n, inset) {
                  ctx.save();
                  ctx.beginPath();
                  ctx.translate(x, y);
                  ctx.moveTo(0, 0 - r);
                  for (var i = 0; i < n; i++) {
                    ctx.rotate(Math.PI / n);
                    ctx.lineTo(0, 0 - r * inset);
                    ctx.rotate(Math.PI / n);
                    ctx.lineTo(0, 0 - r);
                  }
                  ctx.closePath();
                  ctx.fill();
                  ctx.restore();
                }
                strokeStar(5, 6, 3, 4, 4);
              }}
            />
          )}
          <Container className="white-bg position-relative pb-4">
            {/* <CountDown gameData={gameData} /> */}

            <Row className="px-3 justify-content-center">
              <Col className="col-12 col-lg-10 col-xl-6 px-2 text-center text-black">
                <h1
                  className="text-white"
                  dangerouslySetInnerHTML={{ __html: t("G1H") }}
                />
                <div className="question-section">
                  <p
                    className="question-text text-center text-white"
                    dangerouslySetInnerHTML={{ __html: t("G1P") }}
                  />
                </div>

                <motion.div
                  className="position-relative"
                  key={currentQuestion}
                  initial={{ opacity: 0, translateX: 0 }}
                  animate={{ opacity: 1, translateX: 0 }}
                  exit={{ opacity: 0, translateX: 100 }}
                  transition={{ duration: 0.5, type: "tween" }}>
                  {showScore === "success" && (
                    <motion.div
                      initial={{ opacity: 0, translateX: 0 }}
                      animate={{ opacity: 1, translateX: 0 }}
                      exit={{ opacity: 0, translateX: 100 }}
                      className="score">
                      <div className="overlay-content d-flex align-items-center justify-content-center px-3 px-md-5">
                        <div>
                          <h1
                            dangerouslySetInnerHTML={{
                              __html: t(gameData.successh),
                            }}
                          />
                          <p
                            className="text-white lead"
                            dangerouslySetInnerHTML={{
                              __html: t(gameData.successp),
                            }}
                          />

                          <Button
                            className="btn btn-orange px-5 mt-3"
                            onClick={() => navigate("/games")}>
                            {t("Next")}
                          </Button>
                        </div>
                      </div>
                      <div className="overlay" />
                    </motion.div>
                  )}
                  {showScore === "fail" && (
                    <motion.div
                      initial={{ opacity: 0, translateX: 0 }}
                      animate={{ opacity: 1, translateX: 0 }}
                      exit={{ opacity: 0, translateX: 100 }}
                      className="score">
                      <div className="overlay-content d-flex align-items-center justify-content-center px-3 px-md-5">
                        <div>
                          <h1
                            dangerouslySetInnerHTML={{
                              __html: t(gameData.failh),
                            }}
                          />
                          <p
                            className="text-white lead"
                            dangerouslySetInnerHTML={{
                              __html: t(gameData.failp),
                            }}
                          />

                          <Button
                            className="btn btn-orange px-5 mt-3"
                            onClick={() => resetGame()}>
                            {t("Playagain")}
                          </Button>
                          <div>
                            <Button
                              className="btn btn-sm px-5 mt-3"
                              onClick={() => completeGame()}>
                              <u>{t("EndChallenge")}</u>
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="overlay" />
                    </motion.div>
                  )}

                  <div className="answer-section px-md-6 py-md-4">
                    <div className="question-section">
                      <div className="question-progress-square">
                        {gameData.step.map((item, index) => (
                          <div
                            key={index}
                            className={`sq ${
                              currentQuestion >= index && "active"
                            } ${score[index] === true && "correct"} ${
                              score[index] === false && "wrong"
                            }   `}></div>
                        ))}
                      </div>
                    </div>

                    <Image
                      src={images[`${gameData.step[currentQuestion].gamePic}`]}
                      className="w-100"
                    />
                  </div>
                  <Row className="mt-3">
                    {gameData.step[currentQuestion].answerOptions.map(
                      (answerOption, index) => (
                        <Col
                          key={index}
                          className={`col-12 col-md-6 ${disable && "pe-none"}`}>
                          <Button
                            className={`btn-answer btn-image bg-orange w-100 px-5  mb-2 mb-md-0`}
                            variant="primary"
                            size="lg"
                            id={"answer_" + index}
                            key={index}
                            onClick={(e, isCorrect) =>
                              handleAnswerOptionClick(e, answerOption.isCorrect)
                            }>
                            {answerOption.answerText}
                          </Button>
                        </Col>
                      )
                    )}
                    <ConfettiInt
                      className="confetti"
                      active={correct}
                      config={config}
                    />
                  </Row>
                </motion.div>
              </Col>
            </Row>
          </Container>
          <img
            className="position-absolute d-none d-xl-block"
            style={{ top: "60px", left: "15%" }}
            src={images["elm-marjetica.png"]}
          />
          <img
            className="position-absolute d-none d-xl-block"
            style={{ top: "20%", right: "10%" }}
            src={images["elm-zvezda.png"]}
          />
          <img
            className="position-absolute d-none d-xl-block"
            style={{ top: "60%", right: "5%" }}
            src={images["elm-marjetica.png"]}
          />

          <img
            className="position-absolute d-none d-xl-block"
            style={{ bottom: "10%", left: "5%" }}
            src={images["elm-skoljka1.png"]}
          />
          <img
            className="position-absolute d-none d-xl-block"
            style={{ bottom: "0%", right: "15%" }}
            src={images["elm-skoljka2.png"]}
          />
        </motion.div>
      ) : null}
    </>
  );
}

function allAreTrue(arr) {
  //return arr.every((element) => element === true);
  const howManyTrue = arr.filter((value) => value).length;

  if (howManyTrue >= 2) {
    return true;
  }
}

// Shuffle array
function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export default QuizSteps;
